import { Typography } from '@mui/material';

const Transactions = () => {
    return (
        <Typography variant="h4" component="h1">
            Транзакции
        </Typography>
    );
};

export default Transactions; 