import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    CircularProgress,
    Paper,
    Grid
} from '@mui/material';
import CryptoChart from '../charts/CryptoChart';
import AnalysisResult from '../analysis/AnalysisResult';

const API_URL = process.env.REACT_APP_API_URL;

const TIMEFRAMES = [
    { value: '1m', label: '1 минута' },
    { value: '5m', label: '5 минути' },
    { value: '15m', label: '15 минути' },
    { value: '30m', label: '30 минути' },
    { value: '1h', label: '1 час' },
    { value: '4h', label: '4 часа' },
    { value: '1d', label: '1 ден' }
];

const Analytics = () => {
    const { user } = useAuth();
    const [marketType, setMarketType] = useState('SPOT');
    const [availablePairs, setAvailablePairs] = useState([]);
    const [selectedPair, setSelectedPair] = useState('');
    const [timeframe, setTimeframe] = useState('15m');
    const [loading, setLoading] = useState(false);
    const [analysisResult, setAnalysisResult] = useState(null);
    const [error, setError] = useState('');

    // Получаване на достъпните двойки според пазара (SPOT/FUTURES)
    useEffect(() => {
        const fetchPairs = async () => {
            try {
                const endpoint = marketType === 'SPOT' ? 
                    '/api/binance/spot-pairs' : 
                    '/api/binance/futures-pairs';
                
                const response = await axios.get(`${API_URL}${endpoint}`, {
                    withCredentials: true
                });
                
                setAvailablePairs(response.data);
                setSelectedPair(response.data[0] || ''); // Избиране на първата двойка по подразбиране
            } catch (error) {
                console.error('Error fetching pairs:', error);
                setError('Грешка при зареждане на търговските двойки');
            }
        };

        fetchPairs();
    }, [marketType]);

    const handleAnalyze = async () => {
        try {
            setLoading(true);
            setError('');

            const response = await axios.post(`${API_URL}/api/analysis/analyze`, {
                marketType,
                symbol: selectedPair,
                interval: timeframe
            }, {
                withCredentials: true
            });

            setAnalysisResult(response.data);
        } catch (error) {
            console.error('Analysis error:', error);
            setError('Грешка при анализа');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: 3, height: '100%' }}>
            {/* Контроли */}
            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>Тип пазар</InputLabel>
                            <Select
                                value={marketType}
                                onChange={(e) => setMarketType(e.target.value)}
                                label="Тип пазар"
                            >
                                <MenuItem value="SPOT">SPOT</MenuItem>
                                <MenuItem value="FUTURES">FUTURES</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>Търговска двойка</InputLabel>
                            <Select
                                value={selectedPair}
                                onChange={(e) => setSelectedPair(e.target.value)}
                                label="Търговска двойка"
                            >
                                {availablePairs.map(pair => (
                                    <MenuItem key={pair} value={pair}>
                                        {pair}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>Времеви интервал</InputLabel>
                            <Select
                                value={timeframe}
                                onChange={(e) => setTimeframe(e.target.value)}
                                label="Времеви интервал"
                            >
                                {TIMEFRAMES.map(tf => (
                                    <MenuItem key={tf.value} value={tf.value}>
                                        {tf.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleAnalyze}
                            disabled={loading || !selectedPair}
                            sx={{ height: '56px' }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Анализирай'}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {/* Резултат от анализа */}
            {analysisResult && (
                <Box sx={{ mt: 2 }}>
                    <AnalysisResult data={analysisResult} />
                </Box>
            )}

            {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default Analytics;