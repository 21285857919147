import { Box, Typography, Paper, Divider, Chip, Grid, TextField, Tooltip, IconButton } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState, useEffect } from 'react';

// Подобрена функция за форматиране на числа
const formatNumber = (number, type = 'price') => {
    const num = parseFloat(number);
    
    switch(type) {
        case 'price':
            // Ако числото е цяло, добавяме .00
            if (Number.isInteger(num)) {
                return num.toFixed(2);
            }
            // За числа с десетични знаци
            const decimalStr = num.toString();
            if (decimalStr.includes('.')) {
                const [whole, decimal] = decimalStr.split('.');
                // Ако има само нули след десетичната, показваме точно две
                if (decimal.replace(/0/g, '') === '') {
                    return `${whole}.00`;
                }
                // Ако има по-малко от два знака след десетичната, допълваме с нули
                if (decimal.length < 2) {
                    return `${whole}.${decimal.padEnd(2, '0')}`;
                }
                // Премахваме излишните нули, но запазваме поне две след десетичната
                const trimmed = decimalStr.replace(/\.?0+$/, '');
                return trimmed.includes('.') ? trimmed : `${trimmed}.00`;
            }
            return `${decimalStr}.00`;
        case 'percentage':
            return num.toFixed(2);
        case 'volume':
            return num.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            });
        case 'usd':
            return num.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        default:
            return num.toString();
    }
};

const AnalysisResult = ({ data }) => {
    console.log('Analysis data:', {
        marketType: data?.marketType,
        fullData: data
    });

    console.log('Raw analysis data:', data);

    // Преместваме useState hooks в началото на компонента
    const [positionPercent, setPositionPercent] = useState(2); // Default 2%
    const [positionSize, setPositionSize] = useState(0); // Инициализираме с 0
    const [leverage, setLeverage] = useState(5); // Default ливъридж 5x - консервативен подход

    // Преместваме useEffect преди проверката за data
    useEffect(() => {
        if (data?.freeUSDT) {
            setPositionSize(data.freeUSDT * (positionPercent/100));
        }
    }, [data?.freeUSDT, positionPercent]);

    // Проверяваме дали data съществува и има правилната структура
    if (!data || Object.keys(data).length === 0) {
        return (
            <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                <Typography variant="h6" color="error">
                    ⚠️ Няма налични данни за анализ
                </Typography>
            </Box>
        );
    }

    // Форматиране на времевия интервал за показване
    const formatTimeframe = (timeframe) => {
        switch(timeframe) {
            case '1m': return '1 минута';
            case '3m': return '3 минути';
            case '5m': return '5 минути';
            case '15m': return '15 минути';
            case '30m': return '30 минути';
            case '1h': return '1 час';
            case '2h': return '2 часа';
            case '4h': return '4 часа';
            case '6h': return '6 часа';
            case '8h': return '8 часа';
            case '12h': return '12 часа';
            case '1d': return '1 ден';
            case '3d': return '3 дни';
            case '1w': return '1 седмица';
            case '1M': return '1 месец';
            default: return timeframe;
        }
    };

    // Използваме timeframe от данните или от priceRange
    const timeframe = data.timeframe || data.priceRange?.timeframe || '15m';

    // Деструктурираме с валидация и default стойности
    const {
        currentPrice = 0,
        priceRange = { 
            min: 0, 
            max: 0 
        },
        volume24h = 0,
        trend = { 
            direction: 'neutral', 
            strength: 0 
        },
        momentum = 0,
        volatility = 0,
        prediction = {
            nextMove: { 
                direction: 'NEUTRAL', 
                strength: 0 
            },
            targetPrice: { 
                up: 0, 
                down: 0 
            },
            confidence: 0
        },
        targetRange = { 
            up: 0, 
            down: 0 
        },
        confidence = 0,
        freeUSDT = 0,
        recommendation = 'NEUTRAL',
        binanceData = null // Добавяме binanceData за достъп до реални данни от Binance
    } = data;

    // Използваме binanceData ако е налично
    const actualPrice = binanceData?.price || currentPrice;
    const actualVolume = binanceData?.volume || volume24h;

    // Форматиране на числа с проверка за валидност
    const formatValue = (value, type = 'price') => {
        if (!value || isNaN(value) || value === 0) {
            return type === 'price' ? 'Няма данни' : 'N/A';
        }
        return formatNumber(value, type);
    };

    // Функция за обработка на промяната в ливъриджа
    const handleLeverageChange = (event) => {
        const newLeverage = parseInt(event.target.value) || 1;
        setLeverage(newLeverage);
    };

    // Функция за изчисляване на новите стойности
    const calculateTradeValues = (percent, size) => {
        const entry = data.currentPrice;
        const isBullish = data.recommendation === 'BULLISH';
        const isFutures = data.marketType === 'FUTURES';
        
        // Използваме фиксираните стойности от анализа
        const stopLoss = data.tradingAdvice.stopLoss;
        const takeProfit = data.tradingAdvice.takeProfit;

        // Изчисляване на ефективния размер с ливъридж за FUTURES
        const effectiveSize = isFutures ? size * leverage : size;

        // Изчисляване на потенциална печалба/загуба спрямо размера на позицията
        const takeProfitPercent = Math.abs((takeProfit - entry) / entry) * 100;
        const stopLossPercent = Math.abs((stopLoss - entry) / entry) * 100;
        
        const potentialProfit = effectiveSize * (takeProfitPercent/100);
        const potentialLoss = effectiveSize * (stopLossPercent/100);

        return {
            stopLoss,
            takeProfit,
            potentialProfit,
            potentialLoss,
            takeProfitPercent,
            stopLossPercent,
            effectiveSize
        };
    };

    // Функция за обработка на промяната в процента
    const handlePercentChange = (event) => {
        const newPercent = parseFloat(event.target.value) || 0;
        setPositionPercent(newPercent);
        setPositionSize(data.freeUSDT * (newPercent/100));
    };

    // Функция за обработка на промяната в размера
    const handleSizeChange = (event) => {
        const newSize = parseFloat(event.target.value) || 0;
        setPositionSize(newSize);
        setPositionPercent((newSize / data.freeUSDT) * 100);
    };

    // Изчисляваме текущите стойности
    const tradeValues = calculateTradeValues(positionPercent, positionSize);

    // Извличаме lastPrice и prevPrice от данните
    const lastPrice = data?.currentPrice || 0;
    const prevPrice = data?.previousPrice || lastPrice * (1 - (data?.trend?.strength || 0) / 100);

    // Преместваме tooltipMessages вътре в компонента, за да има достъп до данните
    const tooltipMessages = {
        leverage: `Ливъридж (кредитно рамо) позволява търговия с по-голям капитал от наличния. 
                   Например, 5x ливъридж означава, че можете да търгувате с 5 пъти повече от вашите средства. 
                   ⚠️ По-високият ливъридж увеличава както потенциалната печалба, така и риска от загуба.`,
        positionSize: `Размерът на позицията определя колко USDT ще използвате за тази сделка. 
                       Препоръчително е да не рискувате повече от 1-2% от вашия баланс за единична сделка.`,
        percentBalance: `Процент от вашия наличен баланс, който ще бъде използван за тази сделка. 
                        Консервативният подход препоръчва 1-2% за управление на риска.`,
        stopLoss: `Stop Loss е нивото, на което позицията ще бъде автоматично затворена за да ограничи загубите. 
                   Изчислява се на база технически анализ и волатилност.`,
        takeProfit: `Take Profit е целевото ниво за печалба, където позицията ще бъде автоматично затворена. 
                     Базира се на технически анализ и потенциал за движение.`,
        trend: `Трендът се изчислява на база процентната промяна между последната цена (${formatNumber(lastPrice, 'price')}) 
                и предходната цена (${formatNumber(prevPrice, 'price')}). 
                ${trend.direction === 'up' 
                  ? `Възходящият тренд показва, че цената се е повишила с ${formatNumber(trend.strength, 'percentage')}%` 
                  : `Низходящият тренд показва, че цената е спаднала с ${formatNumber(trend.strength, 'percentage')}%`}. 
                Силата на тренда се определя от процента на промяна.`,
        momentum: `Моментумът се изчислява чрез сравнение на текущата цена с цената преди 10 периода. 
                   ${momentum > 0 
                     ? 'Положителният моментум показва ускорение в посока нагоре, което е признак за силен пазар.' 
                     : 'Отрицателният моментум показва ускорение в посока надолу, което е признак за слаб пазар.'} 
                   Този индикатор помага да се определи силата на текущото движение.`,
        volatility: `Волатилността е мярка за пазарната нестабилност, изчислена на база стандартното отклонение 
                     на ценовите промени за последните 20 периода. 
                     Текуща волатилност: ${formatNumber(volatility, 'percentage')}%
                     ${volatility > 5 
                       ? '⚠️ Високата волатилност означава повишен риск и възможност за резки движения в цената.' 
                       : 'Ниската волатилност показва относително спокоен пазар.'}`,
        prediction: `Прогнозата се базира на комбинация от текущия тренд (${formatNumber(trend.strength, 'percentage')}%) 
                     и волатилността (${formatNumber(volatility, 'percentage')}%).
                     ${prediction.nextMove.direction === 'UP' 
                       ? `Очакваното повишение от ${formatNumber(prediction.nextMove.strength, 'percentage')}% 
                          е изчислено на база силата на възходящия тренд и текущата волатилност.`
                       : `Очакваното понижение от ${formatNumber(prediction.nextMove.strength, 'percentage')}% 
                          е изчислено на база силата на низходящия тренд и текущата волатилност.`}`,
        targetRange: `Целевият диапазон е изчислен чрез прилагане на очакваната промяна към текущата цена:
                      • Горна граница: ${formatNumber(targetRange.up, 'price')} 
                        (текуща цена + ${formatNumber(prediction.nextMove.strength, 'percentage')}%)
                      • Долна граница: ${formatNumber(targetRange.down, 'price')} 
                        (текуща цена - ${formatNumber(prediction.nextMove.strength, 'percentage')}%)
                      Увереността в прогнозата (${formatNumber(confidence, 'percentage')}%) 
                      се базира на силата на тренда и стабилността на пазара.`,
        confidence: `Увереността в прогнозата се изчислява по следната формула:
                     Базова увереност (50%) + Сила на тренда (${formatNumber(trend.strength, 'percentage')}%) × 10
                     ${confidence > 70 
                       ? 'Високата увереност показва силен и стабилен тренд.' 
                       : 'По-нисата увереност предполага по-несигурен или променлив пазар.'}`,
    };

    return (
        <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
            {/* Пазарна информация */}
            <Typography variant="h5" gutterBottom color="primary">
                📊 Пазарна информация
            </Typography>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Текуща цена
                        </Typography>
                        <Typography variant="h6">
                            ${formatNumber(currentPrice, 'price')}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Ценови диапазон ({formatTimeframe(timeframe)})
                        </Typography>
                        <Typography variant="body1">
                            ${formatNumber(priceRange.min, 'price')} - ${formatNumber(priceRange.max, 'price')}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            24ч Обем
                        </Typography>
                        <Typography variant="body1">
                            ${formatNumber(data.volume24h, 'volume')}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Обем ({formatTimeframe(timeframe)})
                        </Typography>
                        <Typography variant="body1">
                            ${formatNumber(data.intervalVolume, 'volume')}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            {/* Технически анализ */}
            <Typography variant="h5" gutterBottom color="primary">
                📈 Технически анализ
            </Typography>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Тренд
                            </Typography>
                            <Tooltip title={tooltipMessages.trend} arrow placement="top">
                                <IconButton size="small">
                                    <InfoOutlinedIcon fontSize="small" color="info" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Typography 
                            variant="body1" 
                            color={trend.direction === 'up' ? 'success.main' : 'error.main'}
                        >
                            {trend.direction === 'up' ? '↗️ Възходящ' : '↘️ Низходящ'} 
                            тренд със сила {formatNumber(Math.abs(trend.strength), 'percentage')}%
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Моментум
                            </Typography>
                            <Tooltip title={tooltipMessages.momentum} arrow placement="top">
                                <IconButton size="small">
                                    <InfoOutlinedIcon fontSize="small" color="info" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Typography 
                            variant="body1"
                            color={momentum > 0 ? 'success.main' : 'error.main'}
                        >
                            {momentum > 0 ? '🚀 Силен положителен' : '🔻 Отрицателен'}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Волатилност
                            </Typography>
                            <Tooltip title={tooltipMessages.volatility} arrow placement="top">
                                <IconButton size="small">
                                    <InfoOutlinedIcon fontSize="small" color="info" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Typography variant="body1">
                            {volatility === 'NaN' ? 'Ниска' : `${volatility}%`}
                            {volatility > 5 ? ' ⚠️ Висок риск' : ''}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            {/* Прогноза */}
            <Typography variant="h5" gutterBottom color="primary">
                📈 Прогноза
            </Typography>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Очаква се {prediction.nextMove.direction === 'UP' ? 'повишение' : 'понижение'} 
                                с приблизително {formatNumber(prediction.nextMove.strength, 'percentage')}%
                            </Typography>
                            <Tooltip title={tooltipMessages.prediction} arrow placement="top">
                                <IconButton size="small">
                                    <InfoOutlinedIcon fontSize="small" color="info" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Целеви диапазон:
                            </Typography>
                            <Typography variant="body1" color="primary">
                                ${formatNumber(prediction.targetPrice.down, 'price')} - ${formatNumber(prediction.targetPrice.up, 'price')}
                            </Typography>
                            <Tooltip title={tooltipMessages.targetRange} arrow placement="top">
                                <IconButton size="small">
                                    <InfoOutlinedIcon fontSize="small" color="info" />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Увереност в прогнозата:
                            </Typography>
                            <Typography variant="body1" color={confidence > 70 ? 'success.main' : 'warning.main'}>
                                {formatNumber(prediction.confidence, 'percentage')}%
                            </Typography>
                            <Tooltip title={tooltipMessages.confidence} arrow placement="top">
                                <IconButton size="small">
                                    <InfoOutlinedIcon fontSize="small" color="info" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            {/* Търговски съвет */}
            <Typography variant="h5" gutterBottom color="primary">
                📈 Търговски съвет
            </Typography>
            <Grid container spacing={2}>
                {/* Първи ред - информация за баланса и полета за въвеждане */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Вашите свободни USDT са: ${formatNumber(data.freeUSDT, 'usd')}
                        </Typography>
                        
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={data.marketType === 'FUTURES' ? 4 : 6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <TextField
                                        label="Процент от баланса"
                                        type="number"
                                        value={positionPercent}
                                        onChange={handlePercentChange}
                                        InputProps={{
                                            endAdornment: '%',
                                            inputProps: { min: 0.1, max: 100, step: 0.1 }
                                        }}
                                        size="small"
                                        fullWidth
                                    />
                                    <Tooltip title={tooltipMessages.percentBalance} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 0.5 }}>
                                            <InfoOutlinedIcon fontSize="small" color="info" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={data.marketType === 'FUTURES' ? 4 : 6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <TextField
                                        label="Размер (USDT)"
                                        type="number"
                                        value={positionSize}
                                        onChange={handleSizeChange}
                                        InputProps={{
                                            inputProps: { min: 0.1, step: 0.1 }
                                        }}
                                        size="small"
                                        fullWidth
                                    />
                                    <Tooltip title={tooltipMessages.positionSize} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 0.5 }}>
                                            <InfoOutlinedIcon fontSize="small" color="info" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>

                            {data.marketType === 'FUTURES' && (
                                <Grid item xs={12} sm={4}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <TextField
                                            label="Ливъридж"
                                            type="number"
                                            value={leverage}
                                            onChange={handleLeverageChange}
                                            InputProps={{
                                                endAdornment: 'x',
                                                inputProps: { min: 1, max: 125, step: 1 }
                                            }}
                                            size="small"
                                            fullWidth
                                        />
                                        <Tooltip title={tooltipMessages.leverage} arrow placement="top">
                                            <IconButton size="small" sx={{ ml: 0.5 }}>
                                                <InfoOutlinedIcon fontSize="small" color="info" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>

                        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                            Препоръчва се {data.recommendation === 'BULLISH' ? 'покупка' : 'продажба'} 
                            с {formatNumber(positionPercent, 'percentage')}% от баланса 
                            ({formatNumber(positionSize, 'usd')} USDT
                            {data.marketType === 'FUTURES' && ` с ${leverage}x ливъридж`})
                        </Typography>

                        {data.marketType === 'FUTURES' && (
                            <Typography variant="caption" color="warning.main" sx={{ display: 'block', mt: 1 }}>
                                ⚠️ Ефективен размер на позицията: ${formatNumber(tradeValues.effectiveSize, 'usd')}
                            </Typography>
                        )}
                    </Paper>
                </Grid>

                {/* Втори ред - информация за вход, SL и TP */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Вход: ${formatNumber(data.currentPrice, 'price')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="subtitle2" color="error.main">
                                        Stop Loss: ${formatNumber(tradeValues.stopLoss, 'price')}
                                    </Typography>
                                    <Tooltip title={tooltipMessages.stopLoss} arrow placement="right">
                                        <IconButton size="small" sx={{ ml: 0.5 }}>
                                            <InfoOutlinedIcon fontSize="small" color="info" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="subtitle2" color="success.main">
                                        Take Profit: ${formatNumber(tradeValues.takeProfit, 'price')}
                                    </Typography>
                                    <Tooltip title={tooltipMessages.takeProfit} arrow placement="right">
                                        <IconButton size="small" sx={{ ml: 0.5 }}>
                                            <InfoOutlinedIcon fontSize="small" color="info" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Трети ред - потенциална печалба/загуба */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle1" gutterBottom color="text.secondary" sx={{ fontWeight: 'bold' }}>
                            При отварянето на тази сделка:
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" color="success.main" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Евентуална печалба:</span>
                                    <span>${formatNumber(tradeValues.potentialProfit, 'usd')} ({formatNumber(tradeValues.takeProfitPercent, 'percentage')}%)</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" color="error.main" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Евентуална загуба:</span>
                                    <span>${formatNumber(tradeValues.potentialLoss, 'usd')} ({formatNumber(tradeValues.stopLossPercent, 'percentage')}%)</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AnalysisResult; 