import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
    Box,
    Typography,
    Paper,
    TextField,
    Button,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tabs,
    Tab
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Компонент за API настройки
const APISettings = ({ user }) => {
    const [apiKeys, setApiKeys] = useState([]);
    const [newApiKey, setNewApiKey] = useState({
        exchange: 'binance',
        apiKey: '',
        apiSecret: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (user) {
            fetchApiKeys();
        }
    }, [user]);

    const fetchApiKeys = async () => {
        try {
            console.log('Fetching API keys...');
            const response = await axios.get(
                `${API_URL}/api/settings/api-keys`,
                { withCredentials: true }
            );
            console.log('API keys response:', response.data);
            setApiKeys(response.data);
        } catch (error) {
            console.error('Error fetching API keys:', error.response || error);
            setError('Грешка при зареждане на API ключове');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!user) {
                setError('Не сте оторизиран. Моля, влезте отново в системата.');
                return;
            }

            console.log('Sending API key request for user:', user.id);
            
            const response = await axios.post(
                `${API_URL}/api/settings/api-keys`,
                newApiKey,
                { 
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            console.log('API key response:', response.data);
            setSuccess('API ключове са добавени успешно');
            setNewApiKey({ exchange: 'binance', apiKey: '', apiSecret: '' });
            fetchApiKeys();
        } catch (error) {
            console.error('Error adding API keys:', error.response || error);
            if (error.response?.status === 401) {
                setError('Сесията ви е изтекла. Моля, влезте отново.');
            } else {
                setError(error.response?.data?.message || 'Грешка при добавяне на API ключове');
            }
        }
    };

    const handleDelete = async (exchange) => {
        try {
            await axios.delete(
                `${API_URL}/api/settings/api-keys/${exchange}`,
                { withCredentials: true }
            );
            setSuccess('API ключове са изтрити успешно');
            fetchApiKeys();
        } catch (error) {
            console.error('Error deleting API keys:', error);
            setError('Грешка при изтриване на API ключове');
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>API Настройки</Typography>
            <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Добави API ключове за Binance
                </Typography>
                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="API Key"
                        value={newApiKey.apiKey}
                        onChange={(e) => setNewApiKey({ ...newApiKey, apiKey: e.target.value })}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        label="API Secret"
                        value={newApiKey.apiSecret}
                        onChange={(e) => setNewApiKey({ ...newApiKey, apiSecret: e.target.value })}
                        margin="normal"
                        required
                        type="password"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2 }}
                    >
                        Добави
                    </Button>
                </Box>
            </Paper>

            <Paper sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Активни API ключове
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Борса</TableCell>
                                <TableCell>Добавен на</TableCell>
                                <TableCell>Статус</TableCell>
                                <TableCell align="right">Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {apiKeys.map((key) => (
                                <TableRow key={key.id}>
                                    <TableCell>{key.exchange}</TableCell>
                                    <TableCell>
                                        {new Date(key.created_at).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        {key.is_active ? 'Активен' : 'Неактивен'}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDelete(key.exchange)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

// Компонент за настройки на профила
const ProfileSettings = ({ user }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Новите пароли не съвпадат');
            return;
        }

        try {
            await axios.post(`${API_URL}/api/auth/change-password`, {
                currentPassword,
                newPassword
            }, { withCredentials: true });

            setSuccess('Паролата е променена успешно');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            setError(error.response?.data?.message || 'Грешка при промяна на паролата');
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>Настройки на профила</Typography>
            <Paper sx={{ p: 2, mb: 2 }}>
                <form onSubmit={handlePasswordChange}>
                    <TextField
                        fullWidth
                        type="password"
                        label="Текуща парола"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        type="password"
                        label="Нова парола"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        type="password"
                        label="Потвърди нова парола"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        margin="normal"
                    />
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Промени парола
                    </Button>
                </form>
                {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
            </Paper>
        </Box>
    );
};

// Главен Settings компонент
const Settings = () => {
    const { user } = useAuth();
    const [currentTab, setCurrentTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Настройки
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                <Tabs 
                    value={currentTab} 
                    onChange={handleTabChange}
                    aria-label="settings tabs"
                >
                    <Tab label="Профил" />
                    <Tab label="API Настройки" />
                </Tabs>
            </Box>

            {currentTab === 0 && <ProfileSettings user={user} />}
            {currentTab === 1 && <APISettings user={user} />}
        </Box>
    );
};

export default Settings; 