import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import {
    Box,
    Typography,
    Grid,
    Paper,
    CircularProgress,
    Alert,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
    const { user, checkAuthStatus } = useAuth();
    const [balances, setBalances] = useState([]);
    const [statistics, setStatistics] = useState({
        totalBalance: '0',
        profit24h: '0',
        loss24h: '0'
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!user) {
                    await checkAuthStatus();
                    return;
                }

                const statsResponse = await axios.get(
                    `${API_URL}/api/binance/statistics`,
                    { withCredentials: true }
                );
                setStatistics(statsResponse.data);

                const balancesResponse = await axios.get(
                    `${API_URL}/api/binance/balances`,
                    { withCredentials: true }
                );
                setBalances(balancesResponse.data);
            } catch (error) {
                if (error.response?.status === 401) {
                    await checkAuthStatus();
                } else {
                    setError(`Failed to fetch data from Binance: ${error.response?.data?.message || error.message}`);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user, checkAuthStatus]);

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
        </Box>
    );

    // Показваме грешка само ако няма никакви данни
    if (error && balances.length === 0) return (
        <Alert severity="error" sx={{ mt: 2 }}>
            {error}
        </Alert>
    );

    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Dashboard
            </Typography>

            {/* Обща статистика */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                        <AccountBalanceIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                Общ баланс
                            </Typography>
                            <Typography variant="h6">
                                ${parseFloat(statistics.totalBalance).toLocaleString()}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                        <TrendingUpIcon sx={{ fontSize: 40, mr: 2, color: 'success.main' }} />
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                24ч Печалба
                            </Typography>
                            <Typography variant="h6" color="success.main">
                                +${parseFloat(statistics.profit24h).toLocaleString()}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                        <TrendingDownIcon sx={{ fontSize: 40, mr: 2, color: 'error.main' }} />
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                24ч Загуба
                            </Typography>
                            <Typography variant="h6" color="error.main">
                                -${parseFloat(statistics.loss24h).toLocaleString()}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            {/* Баланси по валути */}
            <Typography variant="h6" gutterBottom>
                Баланси по валути
            </Typography>
            {balances.length > 0 ? (
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    {balances.map((balance) => (
                        <Grid item xs={12} md={4} key={balance.currency}>
                            <Card>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                        <Typography variant="h6" component="div">
                                            {balance.currency}
                                        </Typography>
                                        <IconButton size="small">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        Количество: {balance.amount}
                                    </Typography>
                                    <Typography variant="h6" component="div">
                                        ${balance.value}
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        color={parseFloat(balance.change) >= 0 ? 'success.main' : 'error.main'}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        {parseFloat(balance.change) >= 0 ? (
                                            <TrendingUpIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                                        ) : (
                                            <TrendingDownIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                                        )}
                                        {Math.abs(parseFloat(balance.change)).toFixed(2)}% / 24ч
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Alert severity="info" sx={{ mb: 4 }}>
                    Няма намерени баланси. Моля, проверете вашите API ключове.
                </Alert>
            )}

            {/* Последни транзакции */}
            <Typography variant="h6" gutterBottom>
                Последни транзакции
            </Typography>
            {/* Add your recent trades component here */}
        </Box>
    );
};

export default Dashboard; 