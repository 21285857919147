import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

const DRAWER_WIDTH = '14.2857%';

const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/home' },
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Analytics', icon: <AnalyticsIcon />, path: '/analytics' },
    { text: 'Transactions', icon: <SwapHorizIcon />, path: '/transactions' },
    { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
];

const MainLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuth();

    const handleLogout = async () => {
        await logout();
        navigate('/login');
    };

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%', bgcolor: 'background.default' }}>
            <Drawer
                variant="permanent"
                sx={{
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        boxSizing: 'border-box',
                        borderRight: '1px solid',
                        borderColor: 'divider',
                    },
                }}
            >
                <List sx={{ mt: 2 }}>
                    {menuItems.map((item) => (
                        <ListItem
                            button
                            key={item.text}
                            onClick={() => navigate(item.path)}
                            sx={{
                                backgroundColor: location.pathname === item.path ? 'action.selected' : 'transparent',
                                '&:hover': {
                                    backgroundColor: 'action.hover',
                                },
                                mb: 1,
                                mx: 1,
                                borderRadius: 1,
                                minHeight: '42px',
                            }}
                        >
                            <ListItemIcon sx={{ 
                                minWidth: '35px',
                                color: location.pathname === item.path ? 'primary.main' : 'text.secondary'
                            }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText 
                                primary={item.text} 
                                sx={{ 
                                    '& .MuiTypography-root': { 
                                        fontSize: '0.85rem',
                                        color: location.pathname === item.path ? 'text.primary' : 'text.secondary'
                                    } 
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ position: 'fixed', bottom: 0, width: DRAWER_WIDTH, p: 2 }}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        onClick={handleLogout}
                        startIcon={<LogoutIcon />}
                        sx={{ 
                            minHeight: '36px',
                            fontSize: '0.85rem'
                        }}
                    >
                        Изход
                    </Button>
                </Box>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: `calc(100% - ${DRAWER_WIDTH})`,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box 
                    sx={{ 
                        width: '80%',
                        maxWidth: '1200px',
                        padding: 3,
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};

export default MainLayout; 