import { Typography } from '@mui/material';

const Home = () => {
    return (
        <Typography variant="h4" component="h1">
            Начална страница
        </Typography>
    );
};

export default Home; 