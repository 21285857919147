import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    console.log('Environment variables:', {
        NODE_ENV: process.env.NODE_ENV,
        REACT_APP_API_URL: process.env.REACT_APP_API_URL,
        REACT_APP_NODE_ENV: process.env.REACT_APP_NODE_ENV
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isDevelopment = process.env.NODE_ENV === 'development';
        console.log('Current environment:', process.env.NODE_ENV);
        console.log('API URL:', API_URL);
        console.log('Full request URL:', `${API_URL}/api/auth/register`);
        console.log('Form data:', formData);
        
        try {
            const response = await axios.post(`${API_URL}/api/auth/register`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            });
            console.log('Registration response:', response);
            navigate('/login');
        } catch (err) {
            console.error('Registration error details:', {
                message: err.message,
                response: err.response?.data,
                status: err.response?.status,
                headers: err.response?.headers,
                environment: process.env.NODE_ENV
            });
            setError(
                err.response?.data?.message || 
                'Възникна грешка при регистрацията'
            );
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Регистрация
                </Typography>
                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="username"
                        label="Потребителско име"
                        value={formData.username}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label="Имейл адрес"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Парола"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Регистрация
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Register; 